import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a63bb50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-player-trainings-root-list-form-remove" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Delete)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Jeste li sigurni?",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--primary el-button--block",
                    "native-type": "submit",
                    loading: _ctx.isLoading,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateForm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Jesam ")
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}