
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppPlayerTrainingsRootListFormCreate} from "@/models/app/player/trainings/root/list/form/create";
import {AppCommonTrainingCategory} from "@/models/app/common/training-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElInputNumber,
  ElSelect,
  ElOption,
  ElDatePicker,
  ElTimeSelect,
  ElButton,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "trainingCategories",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElSelect,
    ElOption,
    ElDatePicker,
    ElTimeSelect,
    ElButton,
    Plus,
  },
})
export default class AppPlayerTrainingsRootListFormCreateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  trainingCategories: Array<AppCommonTrainingCategory> = [];

  formData: AppPlayerTrainingsRootListFormCreate = new AppPlayerTrainingsRootListFormCreate();
  formDataRules = {
    user_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    organization_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    training_category_id: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  trainingCategory: AppCommonTrainingCategory = new AppCommonTrainingCategory();

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  setTrainingCategory(): void {
    const trainingCategory = this.trainingCategories.find((item: AppCommonTrainingCategory) => {
      return item.id === this.formData.training_category_id;
    });

    if (trainingCategory) {
      this.trainingCategory = trainingCategory;

      const fields = [];
      this.trainingCategory.content.fields.forEach((item: any) => {
        fields.push({
          value: "",
          name: item.name,
          property: item.property,
        })
      });
      this.formData.content.fields = fields;
    }
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/training/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getTrainings');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  resetFormData(): void {
    this.formData = new AppPlayerTrainingsRootListFormCreate();
    this.trainingCategory = new AppCommonTrainingCategory();
  }
}
