
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppCommonTrainingCategory} from "@/models/app/common/training-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import PlayerNavigationVue from "@/components/player/navigation.vue";
import AppPlayerTrainingsRootFilterIndexVue from "@/views/app/player/trainings/root/filter/index.vue";
import AppPlayerTrainingsRootListIndexVue from "@/views/app/player/trainings/root/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    PlayerNavigationVue,
    AppPlayerTrainingsRootFilterIndexVue,
    AppPlayerTrainingsRootListIndexVue,
  },
})
export default class AppPlayerTrainingsRootIndexVue extends Vue {
  isLoading: boolean | null = false;

  trainingCategories: Array<AppCommonTrainingCategory> = [];

  $refs!: {
    AppPlayerTrainingsRootListIndexVue: any;
  };

  getTrainingsByFilter(formData: any): void {
    this.$refs.AppPlayerTrainingsRootListIndexVue.getTrainings(formData);
  }

  async getMatchCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/training-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.trainingCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getMatchCategories();
  }
}
